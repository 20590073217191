import * as React from "react"
// import PropTypes from "prop-types"

import "./container.css"

const Container = ({ className, children }) => {
    return (
      <div className={`${className} content-container`}>
          {children}
      </div>
    )
  }

export default Container